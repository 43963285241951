
.App-login{
    background-color: #f0f0f0;
}
.login-logo{
    /* padding-top: 160px; */
}               
.all-heigth {
    height: 100vh;
}
.pass-forget{
    text-align: center;
}
.up-desing{
    background-color: #0c879c;
    width: 100%;
    height: 7px;
    margin: 0;
    padding: 0;
}
h3{
    font-size: 22px !important;
    color: #00282f;
    font-family: 'Roboto Condensed', sans-serif;
}
input, button{
    font-size: 18px !important;
    font-family: 'Roboto Condensed', sans-serif;
}
p{
    font-size: 22px !important;
    color: #002a31;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}
.textTittle{
    color: #002a31;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    text-align: center;
}
